<template>
  <section>
    <div class="columns pr-3">
      <div class="column is-2">
        <figure class="image is-128x128">
          <img
            class="is-rounded"
            :src=profileImage
          >
        </figure>
      </div>
      <div class="column is-7">
        <div class="columns mb-0">
          <div class="column">
            <div class="is-flex is-align-items-center">
              <h2 class="pr-3 is-size-3">{{fullName}}</h2>
              <svg
                v-if="isVerified"
                width="30px"
                height="30px"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                aria-hidden="true"
                role="img"
                class="iconify iconify--emojione"
                preserveAspectRatio="xMidYMid meet"
              >
                <circle
                  cx="32"
                  cy="32"
                  r="30"
                  fill="#4bd37b"
                ></circle>
                <path
                  fill="#fff"
                  d="M46 14L25 35.6l-7-7.2l-7 7.2L25 50l28-28.8z"
                ></path>
              </svg>
            </div>
            <div class="is-flex pt-3 gap-20">
              <a
                :href=userUrl
                target="_blank"
                rel="noopener noreferrer"
              >{{username}}</a>
              <div
                class="is-flex gap-5"
                v-if="country || city"
              >
                <svg
                  width="24px"
                  height="24px"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 362.13 362.13"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  enable-background="new 0 0 362.13 362.13"
                >
                  <g>
                    <path d="m181.065,0c-75.532,0-136.981,61.45-136.981,136.981 0,31.155 21.475,76.714 63.827,135.411 30.619,42.436 60.744,75.965 62.011,77.372l11.144,12.367 11.144-12.367c1.267-1.406 31.392-34.936 62.011-77.372 42.352-58.697 63.827-104.255 63.827-135.411-0.001-75.531-61.451-136.981-136.983-136.981zm0,316.958c-37.733-44.112-106.981-134.472-106.981-179.977 0-58.989 47.991-106.981 106.981-106.981s106.981,47.992 106.981,106.981c0.001,45.505-69.248,135.865-106.981,179.977z" />
                    <circle
                      cx="181.065"
                      cy="136.982"
                      r="49.696"
                    />
                  </g>
                </svg>
                <span>{{country}} / {{city}}</span>
              </div>

              <div
                v-if="languages"
                class="is-flex gap-5"
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-labelledby="languageIconTitle"
                  stroke="#000000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  fill="none"
                  color="#000000"
                >
                  <title id="languageIconTitle">Language</title>
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                  />
                  <path
                    stroke-linecap="round"
                    d="M12,22 C14.6666667,19.5757576 16,16.2424242 16,12 C16,7.75757576 14.6666667,4.42424242 12,2 C9.33333333,4.42424242 8,7.75757576 8,12 C8,16.2424242 9.33333333,19.5757576 12,22 Z"
                  />
                  <path
                    stroke-linecap="round"
                    d="M2.5 9L21.5 9M2.5 15L21.5 15"
                  />
                </svg>
                <span>{{languages[0]}}</span>
              </div>
            </div>
          </div>
          <div class="column has-text-right">
            <div>
              <span class="is-size-3"><b>{{followersCount}}</b></span>
            </div>
            <div>
              <span>Подписчиков</span>
            </div>
          </div>
        </div>
        <ul class="is-flex menu-list">
          <li
            class="box p-2 m-0 mr-2 has-background-info-light has-text-info-dark"
            v-for="item in categories"
            v-bind:key="item"
          >{{item}}</li>
        </ul>
        <div class="columns py-3">
          <div class="column">
            <div>{{biography}}</div>
            <div class="py-3">
              <a
                :href=externalUrl
                target="_blank"
                rel="noopener noreferrer"
              >{{externalUrl}}</a>
            </div>
            <div>
              <span>Followings: <b>{{followingCount}}</b></span>
              <span class="px-3">|</span>
              <span>Posts: <b>{{postsCount}}</b></span>
            </div>
          </div>
          <div class="column is-5">
            <LineChart
              :chartName="'Followers Growth'"
              :chartCategories=followersGrowDate
              :chartInformation=followersGrow
            />
          </div>
        </div>
      </div>
      <div class="column is-3 box has-background-white-ter p-0 m-0">
        <div class="columns m-0 p-3">
          <div class="column">
            <span class="is-size-8"><b>Качество аккаунта</b></span>
            <b-progress
              type="is-success"
              :value=accountScore
              class="pt-3"
            ></b-progress>
          </div>
          <div class="column is-5 p-0 box is-align-self-center has-text-centered has-background-success has-text-white">
            <b class="is-size-3">{{accountScore}}</b>/ 100
          </div>
        </div>
        <hr class="has-background-grey-lighter my-3" />
        <div class="columns m-0 p-3">
          <div class="column">
            <div><b class="is-size-4">{{er}} %</b></div>
            <div class="is-size-7">Engagement Rate (ER)</div>
          </div>
          <div class="column">
            <span><b class="is-size-4">{{qualityAudienceCount}}</b></span>
            <div class="is-size-7">Качественной аудитории</div>
          </div>
        </div>
        <hr class="has-background-grey-lighter my-3" />
        <div>
          <div class="is-size-7 px-5 py-3">Обзор:</div>
          <div class="columns m-0 px-3">
            <div class="column is-6">
              <div><b class="is-size-4">{{likesMedian}}</b></div>
              <div class="is-size-7">Лайков на пост</div>
            </div>
            <div class="column is-6">
              <span><b class="is-size-4">{{commentsMedian}}</b></span>
              <div class="is-size-7">Комментариев на пост</div>
            </div>
          </div>
          <div class="columns m-0 px-3 pb-3">
            <div class="column is-6">
              <div><b class="is-size-4">{{viewsMedian}}</b></div>
              <div class="is-size-7">Просмотров на видео</div>
            </div>
            <!-- <div class="column is-6">
              <span><b class="is-size-4">12.42</b></span>
              <div class="is-size-7">Публикаций за 7 дней</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import LineChart from "@/components/charts/LineChart.vue";
export default {
  name: "userProfile",
  components: {
    LineChart,
  },
  computed: mapGetters([
    "er",
    "city",
    "profileImage",
    "fullName",
    "isVerified",
    "userUrl",
    "username",
    "country",
    "languages",
    "followersCount",
    "followingCount",
    "categories",
    "biography",
    "externalUrl",
    "externalUrl",
    "postsCount",
    "followersGrowDate",
    "followersGrow",
    "accountScore",
    "qualityAudienceCount",
    "likesMedian",
    "commentsMedian",
    "viewsMedian",
  ]),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.gap-20 {
  gap: 20px;
}
.gap-5 {
  gap: 5px;
}
</style>
