<template>
  <section class="section is-medium px-3 pb-0">
    <h2 class="title">
      Посты
    </h2>
    <div class="columns pt-6">
      <div class="column">
        <p class="p-3 is-size-6"><b>Likes Spread</b></p>
        <p class="p-3 is-size-6">Spread in likes between posts is <b>117.85%</b></p>
        <BubbleChart />
      </div>
      <div class="column">
        <p class="p-3 is-size-6"><b>Основные хэштеги</b></p>
        <p class="p-6 is-size-6">Не найдено</p>
      </div>
    </div>

  </section>
</template>

<script>
import BubbleChart from "@/components/charts/BubbleChart.vue";
export default {
  name: "posts",
  components: { BubbleChart },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
