<template>
  <section
    class="section is-medium px-3 pb-0"
    v-if="$store.getters.posts"
  >
    <hr class="has-background-grey-lighter" />
    <h2 class="title">
      Публикации
    </h2>
    <div class="is-flex is-flex-wrap-wrap">
      <div
        class="column is-4"
        v-for="item in $store.getters.posts"
        v-bind:key="item.pk"
      >

        <div class="card pt-3 is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center">
          <div class="a">
            <div class="b">
              <div class="c">
                <div class="d">
                  <div class="e">
                    <iframe
                      height="600"
                      :src="`https://www.instagram.com/p/${item.shortcode}/embed`"
                      frameborder="0"
                      style="width: 100%;"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-content">
            <div class="content has-text-centered">
              <ul class="is-flex menu-list m-0">
                <li
                  class="box p-2 m-0 has-background-info-light has-text-info-dark item"
                  title="Просмотры"
                  v-if="item.views"
                >
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="24px"
                    height="24px"
                    viewBox="0 0 59.2 59.2"
                    style="enable-background:new 0 0 59.2 59.2;"
                    xml:space="preserve"
                  >
                    <g>
                      <path d="M51.062,21.561c-5.759-5.759-13.416-8.931-21.561-8.931S13.7,15.801,7.941,21.561L0,29.501l8.138,8.138
		c5.759,5.759,13.416,8.931,21.561,8.931s15.802-3.171,21.561-8.931l7.941-7.941L51.062,21.561z M49.845,36.225
		c-5.381,5.381-12.536,8.345-20.146,8.345s-14.765-2.963-20.146-8.345l-6.724-6.724l6.527-6.527
		c5.381-5.381,12.536-8.345,20.146-8.345s14.765,2.963,20.146,8.345l6.724,6.724L49.845,36.225z" />
                      <path d="M29.572,16.57c-7.168,0-13,5.832-13,13s5.832,13,13,13s13-5.832,13-13S36.741,16.57,29.572,16.57z M29.572,24.57
		c-2.757,0-5,2.243-5,5c0,0.552-0.448,1-1,1s-1-0.448-1-1c0-3.86,3.14-7,7-7c0.552,0,1,0.448,1,1S30.125,24.57,29.572,24.57z" />
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                  </svg>
                  {{item.views}}
                </li>

                <li
                  class="box p-2 m-0 has-background-info-light has-text-info-dark item"
                  title="Лайки"
                  v-if="item.likes"
                >
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="20px"
                    height="20px"
                    viewBox="0 0 51.997 51.997"
                    style="enable-background:new 0 0 51.997 51.997;"
                    xml:space="preserve"
                  >
                    <g>
                      <path d="M51.911,16.242C51.152,7.888,45.239,1.827,37.839,1.827c-4.93,0-9.444,2.653-11.984,6.905
		c-2.517-4.307-6.846-6.906-11.697-6.906c-7.399,0-13.313,6.061-14.071,14.415c-0.06,0.369-0.306,2.311,0.442,5.478
		c1.078,4.568,3.568,8.723,7.199,12.013l18.115,16.439l18.426-16.438c3.631-3.291,6.121-7.445,7.199-12.014
		C52.216,18.553,51.97,16.611,51.911,16.242z M49.521,21.261c-0.984,4.172-3.265,7.973-6.59,10.985L25.855,47.481L9.072,32.25
		c-3.331-3.018-5.611-6.818-6.596-10.99c-0.708-2.997-0.417-4.69-0.416-4.701l0.015-0.101C2.725,9.139,7.806,3.826,14.158,3.826
		c4.687,0,8.813,2.88,10.771,7.515l0.921,2.183l0.921-2.183c1.927-4.564,6.271-7.514,11.069-7.514
		c6.351,0,11.433,5.313,12.096,12.727C49.938,16.57,50.229,18.264,49.521,21.261z" />
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                  </svg>
                  {{item.likes}}
                </li>
                <li
                  class="box p-2 m-0 has-background-info-light has-text-info-dark item"
                  title="Комментарии"
                  v-if="item.comments"
                >
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 -2 28 28"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m14 2c-.038 0-.083-.001-.128-.001-2.098 0-4.102.399-5.942 1.124l.11-.038c-1.74.644-3.217 1.645-4.4 2.922l-.007.007c-.99 1.036-1.608 2.436-1.633 3.98v.005c.013 1.258.432 2.415 1.131 3.351l-.011-.015c.835 1.131 1.885 2.053 3.096 2.719l.049.025 1.36.782-.426 1.498c-.272 1.021-.646 1.915-1.122 2.741l.029-.054c1.651-.691 3.071-1.59 4.313-2.686l-.017.014.672-.594.89.094c.607.077 1.312.122 2.028.125h.004c.038 0 .083.001.128.001 2.098 0 4.102-.399 5.942-1.124l-.11.038c1.74-.644 3.217-1.645 4.4-2.922l.007-.007c1.009-1.025 1.632-2.432 1.632-3.984s-.623-2.96-1.633-3.985l.001.001c-1.19-1.284-2.666-2.286-4.329-2.904l-.078-.025c-1.73-.687-3.735-1.086-5.833-1.086-.044 0-.088 0-.132.001h.007zm14 8c-.012 1.924-.72 3.681-1.884 5.033l.009-.01c-1.349 1.592-3.059 2.837-5.008 3.611l-.086.03c-2.023.846-4.374 1.337-6.839 1.337-.068 0-.135 0-.202-.001h.01c-.8-.002-1.588-.047-2.363-.134l.097.009c-1.981 1.759-4.399 3.072-7.069 3.756l-.118.026c-.503.145-1.107.266-1.726.339l-.055.005h-.08c-.163 0-.311-.062-.422-.164-.123-.111-.212-.258-.249-.424l-.001-.005v-.016c-.017-.029-.027-.064-.027-.102 0-.033.008-.063.021-.091l-.001.001c.02-.047.031-.101.031-.159 0-.001 0-.001 0-.002q-.008-.031.07-.149l.094-.141.11-.133.125-.141q.11-.125.484-.539l.539-.594q.164-.18.484-.617c.174-.231.343-.493.491-.767l.017-.033q.187-.359.422-.922c.137-.317.276-.712.39-1.117l.017-.07c-1.558-.861-2.852-2.018-3.844-3.405l-.024-.035c-.878-1.216-1.407-2.735-1.414-4.377v-.002c.012-1.924.72-3.681 1.884-5.033l-.009.01c1.349-1.592 3.059-2.837 5.008-3.611l.086-.03c2.023-.846 4.374-1.337 6.839-1.337.068 0 .135 0 .202.001h-.01c.057-.001.125-.001.192-.001 2.465 0 4.816.491 6.959 1.381l-.12-.044c2.034.804 3.745 2.048 5.078 3.622l.015.018c1.155 1.342 1.863 3.099 1.875 5.021v.003z" />
                  </svg>
                  {{item.comments}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "publications",
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card {
  height: 100%;
}
ul {
  margin: 0px;
  justify-content: center;
  gap: 10px;
}
.a {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 85%;
  overflow: hidden;
}
.b {
  padding: 12px;
  position: relative;
  margin-top: 0;
  margin: 0;
  box-sizing: content-box;
}
.c {
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  font-family: Gilroy, Roboto, "Helvetica Neue", Arial, sans-serif;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  width: 100%;
  height: auto;
  //   box-shadow: 0 0 1px rgb(53 66 91 / 10%), 0 8px 22px -6px rgb(53 66 91 / 10%);
  padding-top: 100%;
  border-width: 1px;
  border-radius: 8px;
}
.d {
  position: absolute;
  top: -54px;
  left: 0px;
  right: 0px;
}

.e {
  width: 100%;
}

.item {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: default;
}
</style>