<template>
  <section
    class="section is-medium px-3 pb-0"
    v-if="$store.getters.mention"
  >
    <hr class="has-background-grey-lighter" />
    <h2 class="title">
      Основные упоминания
    </h2>
    <p>
      Упоминания других аккаунтов
    </p>
    <div class="is-flex is-flex-wrap-wrap">
      <div
        class="column is-3"
        v-for="item in $store.getters.mention"
        v-bind:key="item.full_name"
      >
        <div class="card pt-3 is-flex is-flex-direction-column is-justify-content-space-between">
          <div class="is-flex is-justify-content-center">
            <figure class="image is-128x128">
              <img
                class="is-rounded"
                :src=item.profile_pic_url
                alt="User image"
              >
            </figure>
          </div>
          <div class="card-content">
            <div class="media has-text-centered">
              <div class="media-content">
                <p class="title is-4 mb-0">{{item.full_name}}</p>
                <a
                  :href="`https://www.instagram.com/${item.username}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="subtitle is-6 has-text-link"
                >{{item.username}}</a>
              </div>
            </div>
            <div class="content has-text-centered">
              <p><b>Mentions - {{item.mentions_count}}</b></p>
              <div class="box p-2 m-0 has-background-info-light has-text-info-dark">{{item.category}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "mentions",
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card {
  height: 100%;
}
</style>
