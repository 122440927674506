<template>
  <section class="section is-medium px-3 pb-0">
    <h2 class="title">
      Комментарии
    </h2>
    <p class="py-3 is-size-5"><b>Комментарии по типам</b></p>
    <div class="columns pt-6">
      <div class="column is-6 is-align-self-center">
        <div class="is-flex gap">
          <div class="button is-rounded has-text-centered circle blogger">
            <span class="has-text-white">
              <svg
                style="width:34px;height:34px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                />
              </svg>
            </span>
          </div>
          <p class="is-align-self-center">Комментарии автора <b>{{commentsByTypeAuthor}}%</b></p>
        </div>
        <div class="is-flex gap mt-3">
          <div class="button is-rounded has-text-centered circle primary">
            <span class="has-text-white">
              <svg
                style="width:34px;height:34px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                />
              </svg>
            </span>
          </div>
          <p class="is-align-self-center">Настоящие <b>{{commentsByTypeReal}}%</b></p>
        </div>
        <div class="is-flex gap mt-3">
          <div class="button is-rounded has-text-centered circle massFollower">
            <span class="has-text-white">
              <svg
                style="width:34px;height:34px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                />
              </svg>
            </span>
          </div>
          <p class="is-align-self-center">Короткие <b>{{commentsByTypeShort}}%</b></p>
        </div>
        <div class="is-flex gap mt-3">
          <div class="button is-rounded has-text-centered circle suspect">
            <span class="has-text-white">
              <svg
                style="width:34px;height:34px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                />
              </svg>
            </span>
          </div>
          <p class="is-align-self-center">Подозрительные <b>{{commentsByTypeSuspicious}}%</b></p>
        </div>
      </div>
      <div class="column">
        <DoughnutChart
          :chartCategories="['Настоящие', 'Короткие', 'Подозрительные', 'Комментарии автора']"
          :chartInformation="[commentsByTypeReal, commentsByTypeShort, commentsByTypeSuspicious, commentsByTypeAuthor]"
          :chartColor="['#2953c0', '#e3e6f2', '#ff3e1d', '#91e0e1']"
        />
      </div>
    </div>
    <div class="columns pt-6">
      <div class="column">
        <div class="is-size-5"><b>Лайкающих и <br> комментирующих с <br> больше чем 2000 <br> подписчиками</b></div>
      </div>
      <div class="column is-align-self-center">
        <div class="columns">
          <div class="column">
            <p><b>Лайкающих {{activityChatLikers}}%</b></p>
            <p class="pt-3"><b>Комментирующих {{activityChatCommenters}}%</b></p>
          </div>
          <div class="column is-align-self-center">
            <b-progress
              type="is-info"
              :value=activityChatLikers
              class="m-0"
              show-value
            ></b-progress>
            <b-progress
              type="is-info"
              :value=activityChatCommenters
              class="mt-5"
              show-value
            ></b-progress>
          </div>
        </div>
      </div>
    </div>
    <div class="columns pt-6">
      <div class="column">
        <div class="is-size-5"><b>Лайкающих и <br> комментирующих с <br> больше чем 2000 <br> подписок</b></div>
      </div>
      <div class="column is-align-self-center">
        <div class="columns">
          <div class="column">
            <p><b>Лайкающих {{massFollowLikers}}%</b></p>
            <p class="pt-3"><b>Комментирующих {{massFollowCommenters}}%</b></p>
          </div>
          <div class="column is-align-self-center">
            <b-progress
              type="is-info"
              :value=massFollowLikers
              class="m-0"
              show-value
            ></b-progress>
            <b-progress
              type="is-info"
              :value=massFollowCommenters
              class="mt-5"
              show-value
            ></b-progress>
          </div>
        </div>
      </div>
    </div>
    <div class="columns pt-6">
      <div class="column">
        <div class="is-size-5"><b>Комментирующих с меньше <br> чем 1000 подписчиков</b></div>
      </div>
      <div class="column is-align-self-center">
        <div class="columns">
          <div class="column">
            <p><b>Комментирующих {{unpopularCommenters}}%</b></p>
          </div>
          <div class="column">
            <b-progress
              type="is-info"
              :value=unpopularCommenters
              show-value
            ></b-progress>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import DoughnutChart from "@/components/charts/DoughnutChart.vue";
export default {
  name: "comments",
  components: {
    DoughnutChart,
  },
  computed: mapGetters([
    "commentsByTypeAuthor",
    "commentsByTypeReal",
    "commentsByTypeShort",
    "commentsByTypeSuspicious",
    "activityChatLikers",
    "activityChatCommenters",
    "massFollowLikers",
    "massFollowCommenters",
    "unpopularCommenters",
  ]),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.circle {
  width: 55px;
  height: 55px;
  cursor: default;
}

.max-height {
  height: 100%;
}

.primary {
  background: #2953c0;
}

.female {
  background: #ffcff2;
}

.male {
  background: #03c3ec;
}

.suspect {
  background: #ff3e1d;
}

.commercial {
  background: #03c3ec;
}

.massFollower {
  background: #e3e6f2;
}

.blogger {
  background: #91e0e1;
}

.gap {
  column-gap: 12px;
}
</style>
