<template>
  <section class="section is-medium px-3 pb-0">
    <h2 class="title">
      Анализ аудитории
    </h2>
    <div class="columns pt-6">
      <div class="column is-4">
        <p><b>Доступность аудитории</b></p>
        <div class="is-flex gap mt-3">
          <div class="button is-rounded has-text-centered circle primary">
            <span class="has-text-white">
              {{audienceReachability}}%
            </span>
          </div>
          <span class="is-align-self-center"><b>{{audienceReachability}}%</b> аудитории следует за меньше чем 1500
            аккаунтами</span>
        </div>
      </div>
      <div class="column is-4">
        <p><b>Подлинность аудитории</b></p>
        <div class="is-flex gap mt-3">
          <div class="button is-rounded has-text-centered circle primary">
            <span class="has-text-white">
              {{qualityAudiencePercent}}%
            </span>
          </div>
          <span class="is-align-self-center"><b>{{qualityAudiencePercent}}%</b> выглядит подлинно</span>
        </div>
      </div>
      <div class="column is-4">
        <p><b>Среднее количество лайков</b></p>
        <div class="is-flex gap mt-3">
          <div class="button is-rounded has-text-centered circle primary">
            <span class="has-text-white">
              ❤
            </span>
          </div>
          <span class="is-align-self-center"><b>{{likesMedian}}</b> лайков на пост</span>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <BarChart
          :chartName="'География аудитории'"
          :chartCategories=audienceByCountryKeys
          :chartInformation=audienceByCountryValues
        />
      </div>
      <div class="column">
        <BarChart
          :chartName="'География аудитории (Лайки)'"
          :chartCategories=audienceByCountryLikesKeys
          :chartInformation=audienceByCountryLikesValues
        />
      </div>
    </div>
    <div class="columns">
      <div class="column p-6">
        <BarChart
          :chartName="'Местоположение аудитории по городам '"
          :chartCategories=audienceByCityKeys
          :chartInformation=audienceByCityValues
        />
      </div>
      <div class="column p-6">
        <BarChart
          :chartName="'Местоположение аудитории по городам (Лайки)'"
          :chartCategories=audienceByCityLikesKeys
          :chartInformation=audienceByCityLikesValues
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <p class="p-3 is-size-5"><b>Пол аудитории</b></p>
        <div class="columns">
          <div class="column is-4 is-align-self-center">
            <div class="is-flex gap">
              <div class="button is-rounded has-text-centered circle female">
                <span class="has-text-white">
                  <svg
                    style="width:34px;height:34px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                    />
                  </svg>
                </span>
              </div>
              <p class="is-align-self-center">Женщины <b>{{audienceByGenderFemale}}%</b></p>
            </div>
            <div class="is-flex gap mt-3">
              <div class="button is-rounded has-text-centered circle male">
                <span class="has-text-white">
                  <svg
                    style="width:34px;height:34px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                    />
                  </svg>
                </span>
              </div>
              <p class="is-align-self-center">Мужчины <b>{{audienceByGenderMale}}%</b></p>
            </div>
          </div>
          <div class="column">
            <DoughnutChart
              :chartCategories="['Женщины', 'Мужчины']"
              :chartInformation="[audienceByGenderFemale, audienceByGenderMale]"
              :chartColor="['#ffcff2', '#03c3ec']"
            />
          </div>
        </div>
      </div>
      <div class="column">
        <p class="p-3 is-size-5"><b>Пол аудитории (Лайки)</b></p>
        <div class="columns">
          <div class="column is-4 is-align-self-center">
            <div class="is-flex gap">
              <div class="button is-rounded has-text-centered circle female">
                <span class="has-text-white">
                  <svg
                    style="width:34px;height:34px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                    />
                  </svg>
                </span>
              </div>
              <p class="is-align-self-center">Женщины <b>{{audienceByGenderFemaleLikes}}%</b></p>
            </div>
            <div class="is-flex gap mt-3">
              <div class="button is-rounded has-text-centered circle male">
                <span class="has-text-white">
                  <svg
                    style="width:34px;height:34px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                    />
                  </svg>
                </span>
              </div>
              <p class="is-align-self-center">Мужчины <b>{{audienceByGenderMaleLikes}}%</b></p>
            </div>
          </div>
          <div class="column">
            <DoughnutChart
              :chartCategories="['Женщины', 'Мужчины']"
              :chartInformation="[audienceByGenderFemaleLikes, audienceByGenderMaleLikes]"
              :chartColor="['#ffcff2', '#03c3ec']"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="columns mt-6">
      <div class="column">
        <p class="p-3 is-size-5"><b>Тип аудитории</b></p>
        <div class="columns ">
          <div class="column is-4 is-size-7 is-align-self-center">
            <div class="is-flex gap">
              <div class="button is-rounded has-text-centered circle primary">
                <span class="has-text-white">
                  <svg
                    style="width:34px;height:34px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                    />
                  </svg>
                </span>
              </div>
              <p class="is-align-self-center">Настоящий <br> <b>{{audienceReal}}%</b></p>
            </div>
            <div class="is-flex gap mt-3">
              <div class="button is-rounded has-text-centered circle suspect">
                <span class="has-text-white">
                  <svg
                    style="width:34px;height:34px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                    />
                  </svg>
                </span>
              </div>
              <p class="is-align-self-center">Подозрительный <br> <b>{{audienceSuspicious}}%</b></p>
            </div>
            <div class="is-flex gap mt-3">
              <div class="button is-rounded has-text-centered circle commercial">
                <span class="has-text-white">
                  <svg
                    style="width:34px;height:34px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                    />
                  </svg>
                </span>
              </div>
              <p class="is-align-self-center">Коммерческий <br> <b>{{audienceCommercial}}%</b></p>
            </div>
            <div class="is-flex gap mt-3">
              <div class="button is-rounded has-text-centered circle massFollower">
                <span class="has-text-white">
                  <svg
                    style="width:34px;height:34px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                    />
                  </svg>
                </span>
              </div>
              <p class="is-align-self-center">Масс фолловер <br> <b>{{audienceMassFollower}}%</b></p>
            </div>
            <div class="is-flex gap mt-3">
              <div class="button is-rounded has-text-centered circle blogger">
                <span class="has-text-white">
                  <svg
                    style="width:34px;height:34px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                    />
                  </svg>
                </span>
              </div>
              <p class="is-align-self-center">Блогер <br> <b>{{audienceInfluencer}}%</b></p>
            </div>
          </div>
          <div class="column">
            <DoughnutChart
              :chartCategories="['Настоящий', 'Подозрительный', 'Коммерческий', 'Масс фолловер', 'Блогер']"
              :chartInformation="[audienceReal, audienceSuspicious, audienceCommercial, audienceMassFollower, audienceInfluencer]"
              :chartColor="['#2953c0', '#ff3e1d',  '#03c3ec', '#e3e6f2', '#91e0e1']"
            />
          </div>
        </div>
      </div>
      <div class="column">
        <p class="p-3 is-size-5"><b>Тип аудитории (Лайки)</b></p>
        <div class="columns">
          <div class="column is-4 is-size-7 is-align-self-center">
            <div class="is-flex gap">
              <div class="button is-rounded has-text-centered circle primary">
                <span class="has-text-white">
                  <svg
                    style="width:34px;height:34px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                    />
                  </svg>
                </span>
              </div>
              <p class="is-align-self-center">Настоящий <br> <b>{{audienceRealLikes}}%</b></p>
            </div>
            <div class="is-flex gap mt-3">
              <div class="button is-rounded has-text-centered circle suspect">
                <span class="has-text-white">
                  <svg
                    style="width:34px;height:34px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                    />
                  </svg>
                </span>
              </div>
              <p class="is-align-self-center">Подозрительный <br> <b>{{audienceSuspiciousLikes}}%</b></p>
            </div>
            <div class="is-flex gap mt-3">
              <div class="button is-rounded has-text-centered circle commercial">
                <span class="has-text-white">
                  <svg
                    style="width:34px;height:34px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                    />
                  </svg>
                </span>
              </div>
              <p class="is-align-self-center">Коммерческий <br> <b>{{audienceCommercialLikes}}%</b></p>
            </div>
            <div class="is-flex gap mt-3">
              <div class="button is-rounded has-text-centered circle massFollower">
                <span class="has-text-white">
                  <svg
                    style="width:34px;height:34px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                    />
                  </svg>
                </span>
              </div>
              <p class="is-align-self-center">Масс фолловер <br> <b>{{audienceMassFollowerLikes}}%</b></p>
            </div>
            <div class="is-flex gap mt-3">
              <div class="button is-rounded has-text-centered circle blogger">
                <span class="has-text-white">
                  <svg
                    style="width:34px;height:34px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                    />
                  </svg>
                </span>
              </div>
              <p class="is-align-self-center">Блогер <br> <b>{{audienceInfluencerLikes}}%</b></p>
            </div>
          </div>
          <div class="column">
            <DoughnutChart
              :chartCategories="['Настоящий', 'Подозрительный', 'Коммерческий', 'Масс фолловер', 'Блогер']"
              :chartInformation="[audienceRealLikes, audienceSuspiciousLikes, audienceCommercialLikes, audienceMassFollowerLikes, audienceInfluencerLikes]"
              :chartColor="['#2953c0', '#ff3e1d',  '#03c3ec', '#e3e6f2', '#91e0e1']"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-6">
      <p class="py-3 is-size-5"><b>Подписчики и подписки</b></p>
      <p class="py-3 is-size-5"><b>+{{followersGrowthWeeks}}</b> подписчиков за последние 4 недели</p>
      <div class="columns mt-3">
        <div class="column">
          <LineChart
            :chartName="'Рост подписчиков'"
            :chartColor="'#ff7d55'"
            :chartCategories=followersGrowDate
            :chartInformation=followersGrow
          />
        </div>
        <div class="column">
          <LineChart
            :chartName="'Рост подписок'"
            :chartColor="'#2953c0'"
            :chartCategories=followingGrowDate
            :chartInformation=followingGrow
          />
        </div>
      </div>
    </div>
    <div class="columns mt-6">
      <div class="column">
        <BarChart
          :chartName="'Частота постов и процент лайков от подписчиков'"
          :chartCategories=postLikeStatsDate
          :chartInformation=postLikeStats
          :chartPosition="'x'"
        />
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import BarChart from "@/components/charts/BarChart.vue";
import DoughnutChart from "@/components/charts/DoughnutChart.vue";
import LineChart from "@/components/charts/LineChart.vue";
export default {
  name: "audienceAnalysis",
  components: {
    BarChart,
    DoughnutChart,
    LineChart,
  },
  computed: mapGetters([
    "audienceReachability",
    "qualityAudiencePercent",
    "likesMedian",
    "audienceByCountryKeys",
    "audienceByCountryValues",
    "audienceByCountryLikesKeys",
    "audienceByCountryLikesValues",
    "audienceByCityKeys",
    "audienceByCityValues",
    "audienceByCityLikesKeys",
    "audienceByCityLikesValues",
    "audienceByGenderFemale",
    "audienceByGenderMale",
    "audienceByGenderFemaleLikes",
    "audienceByGenderMaleLikes",
    "audienceReal",
    "audienceSuspicious",
    "audienceCommercial",
    "audienceMassFollower",
    "audienceInfluencer",
    "audienceRealLikes",
    "audienceSuspiciousLikes",
    "audienceCommercialLikes",
    "audienceMassFollowerLikes",
    "audienceInfluencerLikes",
    "followersGrowthWeeks",
    "followersGrowDate",
    "followersGrow",
    "followingGrow",
    "followingGrowDate",
    "postLikeStats",
    "postLikeStatsDate",
  ]),
};
</script>
<style scoped lang="scss">
.circle {
  width: 55px;
  height: 55px;
  cursor: default;
}

.primary {
  background: #2953c0;
}

.female {
  background: #ffcff2;
}

.male {
  background: #03c3ec;
}

.suspect {
  background: #ff3e1d;
}

.commercial {
  background: #03c3ec;
}

.massFollower {
  background: #e3e6f2;
}

.blogger {
  background: #91e0e1;
}

.gap {
  column-gap: 12px;
}
</style>