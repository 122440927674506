<template>
  <section>
    <div class="is-size-1 has-text-centered">
      takoe.DEV
    </div>
  </section>
</template>

<script>
export default {
  name: "Home",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
