<template>
  <section>
    <header>
      <div class="columns">
        <div class="column">
          <h1 class="title">
            takoe.DEV
          </h1>
          <p>
            Анализ аккаунта
          </p>
          <p class="subtitle">
            {{new Date().toLocaleDateString()}}
          </p>
        </div>
        <div class="column has-text-right is-align-self-center">
          <a
            href="https://takoe.dev/"
            target="_blank"
            rel="noopener noreferrer"
            class="subtitle"
          >https://takoe.dev/</a>
        </div>
      </div>
      <hr class="has-background-grey-lighter" />
    </header>
  </section>
</template>

<script>
export default {
  name: "headerProfile",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
