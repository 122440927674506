import axios from "axios";
import store from "./store/index";

const axiosInstance  = axios.create();

axiosInstance .interceptors.request.use(function (config) {
    store.commit("preloaderToggle");
    return config;
  }, function (error) {
    store.commit("preloaderToggle");
    return Promise.reject(error);
  });

// Add a response interceptor
axiosInstance .interceptors.response.use(function (response) {
    store.commit("preloaderToggle");
    return response;
  }, function (error) {
    store.commit("preloaderToggle");
    return Promise.reject(error);
  });

  export default axiosInstance 