<template>
  <section>
    <div
      v-if="this.$store.state.preloader"
      class="is-flex is-justify-content-center is-align-items-center vh"
    >
      <preloader />
    </div>
    <div v-if="!this.$store.state.preloader && this.$store.state.response">
      <headerProfile />
      <userProfile />
      <audienceAnalysis />
      <comments />
      <posts />
      <mentions />
      <publications />
    </div>
  </section>
</template>

<script>
import preloader from "@/components/preloader.vue";
import axiosInstance from "../axios";
import headerProfile from "@/components/headerProfile.vue";
import userProfile from "@/components/userProfile.vue";
import audienceAnalysis from "@/components/audienceAnalysis.vue";
import comments from "@/components/comments.vue";
import posts from "@/components/posts.vue";
import mentions from "@/components/mentions.vue";
import publications from "@/components/publications.vue";
import { PINATA_URL, REPORTS_URL } from "../settings";
export default {
  name: "Main",
  components: {
    preloader,
    headerProfile,
    userProfile,
    audienceAnalysis,
    comments,
    posts,
    mentions,
    publications,
  },
  created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        if (toParams !== previousParams) window.location.reload();
      }
    );
  },
  mounted() {
    this.getJson();
  },
  methods: {
    getJson() {
      const self = this;
      if (self.$route.params.json_file_prefix.length === 46) {
        axiosInstance
          .get(PINATA_URL + self.$route.params.json_file_prefix)
          .then(function (response) {
            self.$store.commit("setResponse", response.data);
            document.title = self.$store.getters.username;
          })
          .catch(() => (window.location.href = "https://t.me/InstAuditorBot"));
        return;
      }
      axiosInstance
        .get(REPORTS_URL + self.$route.params.json_file_prefix + ".json")
        .then(function (response) {
          self.$store.commit("setResponse", response.data);
          document.title = self.$store.getters.username;
        })
        .catch(() => (window.location.href = "https://t.me/InstAuditorBot"));
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.vh {
  min-height: 50vh;
}
</style>
