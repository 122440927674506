import Vue from "vue";
import Vuex from "vuex";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    response: null,
    preloader: false,
  },
  getters: {
    profileImage(state) {
      return state.response.preview.user_info.profile_pic_url;
    },
    fullName(state) {
      return state.response.preview.user_info.full_name;
    },
    username(state) {
      return state.response.preview.user_info.username;
    },
    userUrl(state, getters) {
      return "https://www.instagram.com/" + getters.username;
    },
    followersCount(state) {
      return state.response.preview.user_info.follower_count;
    },
    followingCount(state) {
      return state.response.preview.user_info.following_count;
    },
    country(state) {
      return state.response.preview.user_info.country;
    },
    city(state) {
      return state.response.preview.user_info.city;
    },
    languages(state) {
      return state.response.preview.user_info.languages;
    },
    biography(state) {
      return state.response.preview.user_info.biography;
    },
    categories(state) {
      return state.response.preview.user_info.topics.detected;
    },
    externalUrl(state) {
      return state.response.preview.user_info.external_url;
    },
    postsCount(state) {
      return state.response.preview.user_info.media_count;
    },
    isVerified(state) {
      return state.response.preview.user_info.is_verified;
    },
    postFrequency(state) {
      return parseFloat(
        state.response.report.posts_report.post_frequency
      ).toFixed(2);
    },
    accountScore(state) {
      return Math.round(state.response.report.account_report.aqs.value);
    },
    er(state) {
      return parseFloat(state.response.preview.user_info.er * 100).toFixed(1);
    },

    likesMedian(state) {
      return Math.round(state.response.report.posts_report.median_likes);
    },
    commentsMedian(state) {
      return Math.round(state.response.report.posts_report.median_comments);
    },
    viewsMedian(state) {
      return Math.round(state.response.report.posts_report.median_views);
    },
    qualityAudienceCount(state) {
      return Math.round(
        state.response.report.audience_report.quality_audience.all
      );
    },
    qualityAudiencePercent(state) {
      return Math.round(
        state.response.report.audience_report.quality_audience.all_percent * 100
      );
    },
    audienceByCountry(state) {
      let data =
        state.response.report.audience_report.audience_by_followers
          .audience_by_country;
      let sortable = [];
      for (let key in data) {
        sortable.push([key, data[key]]);
      }

      sortable.sort(function (a, b) {
        return b[1] - a[1];
      });
      return sortable;
    },
    audienceByCountryKeys(state, getters) {
      let result = [];
      getters.audienceByCountry.forEach(el => result.push(el[0]));
      return result.slice(0,6)
    },
    audienceByCountryValues(state, getters) {
      let result = [];
      getters.audienceByCountry.forEach(el => result.push(el[1]));
      result.slice(0,6)
      return result.map((el) => el * 100);
    },
    audienceByCountryLikes(state) {
      let data =
      state.response.report.audience_report.audience_by_country;
      let sortable = [];
      for (let key in data) {
        sortable.push([key, data[key]]);
      }

      sortable.sort(function (a, b) {
        return b[1] - a[1];
      });
      return sortable;
    },
    audienceByCountryLikesKeys(state, getters) {
      let result = [];
      getters.audienceByCountryLikes.forEach(el => result.push(el[0]));
      return result.slice(0,6)
    },
    audienceByCountryLikesValues(state, getters) {
      let result = [];
      getters.audienceByCountryLikes.forEach(el => result.push(el[1]));
      result.slice(0,6)
      return result.map((el) => el * 100);
    },
    audienceByCity(state) {
        let data =
        state.response.report.audience_report.audience_by_followers
        .audience_by_city;
      let sortable = [];
      for (let key in data) {
        sortable.push([key, data[key]]);
      }

      sortable.sort(function (a, b) {
        return b[1] - a[1];
      });
      return sortable;
    },
    audienceByCityKeys(state, getters) {
      let result = [];
      getters.audienceByCity.forEach(el => result.push(el[0]));
      return result.slice(0,11)
    },
    audienceByCityValues(state, getters) {
      let result = [];
      getters.audienceByCity.forEach(el => result.push(el[1]));
      result.slice(0,11)
      return result.map((el) => el * 100);
    },
    audienceByCityLikes(state) {
      let data =
      state.response.report.audience_report.audience_by_city;
    let sortable = [];
    for (let key in data) {
      sortable.push([key, data[key]]);
    }

    sortable.sort(function (a, b) {
      return b[1] - a[1];
    });
    return sortable;
    },
    audienceByCityLikesKeys(state, getters) {
      let result = [];
      getters.audienceByCityLikes.forEach(el => result.push(el[0]));
      return result.slice(0,11)
    },
    audienceByCityLikesValues(state, getters) {
      let result = [];
      getters.audienceByCityLikes.forEach(el => result.push(el[1]));
      result.slice(0,11)
      return result.map((el) => el * 100);
    },
    audienceReachability(state) {
      return Math.round(
        state.response.report.audience_report.audience_reachability.value * 100
      );
    },
    audienceByGenderFemale(state) {
      return Math.round(
        state.response.report.audience_report.audience_by_followers
          .audience_by_gender.female * 100
      );
    },
    audienceByGenderMale(state) {
      return Math.round(
        state.response.report.audience_report.audience_by_followers
          .audience_by_gender.male * 100
      );
    },
    audienceByGenderFemaleLikes(state) {
      return Math.round(
        state.response.report.audience_report.audience_by_gender.female * 100
      );
    },
    audienceByGenderMaleLikes(state) {
      return Math.round(
        state.response.report.audience_report.audience_by_gender.male * 100
      );
    },
    audienceMassFollower(state) {
      return parseFloat(
        state.response.report.audience_report.audience_by_type.mass_follower *
          100
      ).toFixed(2);
    },
    audienceReal(state) {
      return parseFloat(
        state.response.report.audience_report.audience_by_type.real * 100
      ).toFixed(2);
    },
    audienceSuspicious(state) {
      return parseFloat(
        state.response.report.audience_report.audience_by_type.suspicious * 100
      ).toFixed(2);
    },
    audienceInfluencer(state) {
      return parseFloat(
        state.response.report.audience_report.audience_by_type.influencer * 100
      ).toFixed(2);
    },
    audienceCommercial(state) {
      return parseFloat(
        state.response.report.audience_report.audience_by_type.commercial * 100
      ).toFixed(2);
    },
    audienceMassFollowerLikes(state) {
      return parseFloat(
        state.response.report.audience_report.audience_by_likers
          .audience_by_type.mass_follower * 100
      ).toFixed(2);
    },
    audienceRealLikes(state) {
      return parseFloat(
        state.response.report.audience_report.audience_by_likers
          .audience_by_type.real * 100
      ).toFixed(2);
    },
    audienceSuspiciousLikes(state) {
      return parseFloat(
        state.response.report.audience_report.audience_by_likers
          .audience_by_type.suspicious * 100
      ).toFixed(2);
    },
    audienceInfluencerLikes(state) {
      return parseFloat(
        state.response.report.audience_report.audience_by_likers
          .audience_by_type.influencer * 100
      ).toFixed(2);
    },
    audienceCommercialLikes(state) {
      return parseFloat(
        state.response.report.audience_report.audience_by_likers
          .audience_by_type.commercial * 100
      ).toFixed(2);
    },
    followersGrowthWeeks(state) {
      return state.response.report.audience_growth["4_weeks_growth"];
    },
    followersGrow(state) {
      return state.response.report.audience_growth.followers_grow;
    },
    followersGrowDate(state) {
      let data = state.response.report.audience_growth.followers_grow.map(
        (el) => new Date(el[0] * 1000).toLocaleDateString()
      );
      return data;
    },
    followingGrow(state) {
      return state.response.report.audience_growth.following_grow;
    },
    followingGrowDate(state) {
      let data = state.response.report.audience_growth.following_grow.map(
        (el) => new Date(el[0] * 1000).toLocaleDateString()
      );
      return data;
    },
    postLikeStats(state) {
      let data = state.response.report.audience_report.post_like_stats.map(
        (el) => el.follower_count
      );
      return data;
    },
    postLikeStatsDate(state) {
      let data = state.response.report.audience_report.post_like_stats.map(
        (el) => new Date(el.taken_at * 1000).toLocaleDateString()
      );
      return data;
    },
    commentsByTypeAuthor(state) {
      return parseFloat(
        state.response.report.audience_report.comments_by_type.author_comments *
          100
      ).toFixed(2);
    },
    commentsByTypeReal(state) {
      return parseFloat(
        state.response.report.audience_report.comments_by_type.real_comments *
          100
      ).toFixed(2);
    },
    commentsByTypeShort(state) {
      return parseFloat(
        state.response.report.audience_report.comments_by_type.short_comments *
          100
      ).toFixed(2);
    },
    commentsByTypeSuspicious(state) {
      return parseFloat(
        state.response.report.audience_report.comments_by_type
          .suspicious_comments * 100
      ).toFixed(2);
    },
    activityChatLikers(state) {
      return Number(
        parseFloat(
          state.response.report.audience_report.commenters_by_type
            .activity_chat_likers * 100
        ).toFixed(2)
      );
    },
    activityChatCommenters(state) {
      return Number(
        parseFloat(
          state.response.report.audience_report.commenters_by_type
            .activity_chat_commenters * 100
        ).toFixed(2)
      );
    },
    massFollowLikers(state) {
      return Number(
        parseFloat(
          state.response.report.audience_report.commenters_by_type
            .massfollow_likers * 100
        ).toFixed(2)
      );
    },
    massFollowCommenters(state) {
      return Number(
        parseFloat(
          state.response.report.audience_report.commenters_by_type
            .massfollow_commenters * 100
        ).toFixed(2)
      );
    },
    unpopularCommenters(state) {
      return Number(
        parseFloat(
          state.response.report.audience_report.commenters_by_type
            .unpopular_commenters * 100
        ).toFixed(2)
      );
    },
    mention(state) {
      return state.response.report.posts_report.mentions;
    },
    posts(state) {
      return state.response.report.posts_report.posts;
    },
  },
  mutations: {
    setResponse(state, json) {
      state.response = json;
    },
    preloaderToggle(state) {
      state.preloader = !state.preloader;
    },
  },
  actions: {},
  modules: {},
});
