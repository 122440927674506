import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../layouts/Main.vue";
import Home from "../layouts/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/:json_file_prefix.html",
    name: "Main",
    component: Main,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
