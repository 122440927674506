<template>
  <section>
    <div class="loadingio-spinner-wedges-u8574bzr5w">
      <div class="ldio-byq5psenbvn">
        <div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "preloader",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@keyframes ldio-byq5psenbvn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-byq5psenbvn > div > div {
  transform-origin: 100px 100px;
  animation: ldio-byq5psenbvn 3.0303030303030303s linear infinite;
  opacity: 0.8;
}
.ldio-byq5psenbvn > div > div > div {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 70px;
  height: 70px;
  border-radius: 70px 0 0 0;
  transform-origin: 100px 100px;
}
.ldio-byq5psenbvn > div div:nth-child(1) {
  animation-duration: 0.7575757575757576s;
}
.ldio-byq5psenbvn > div div:nth-child(1) > div {
  background: #8cd0e5;
  transform: rotate(0deg);
}
.ldio-byq5psenbvn > div div:nth-child(2) {
  animation-duration: 1.0101010101010102s;
}
.ldio-byq5psenbvn > div div:nth-child(2) > div {
  background: #376888;
  transform: rotate(0deg);
}
.ldio-byq5psenbvn > div div:nth-child(3) {
  animation-duration: 1.5151515151515151s;
}
.ldio-byq5psenbvn > div div:nth-child(3) > div {
  background: #826b88;
  transform: rotate(0deg);
}
.ldio-byq5psenbvn > div div:nth-child(4) {
  animation-duration: 3.0303030303030303s;
}
.ldio-byq5psenbvn > div div:nth-child(4) > div {
  background: #de786a;
  transform: rotate(0deg);
}
.loadingio-spinner-wedges-u8574bzr5w {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: whitesmoke;
}
.ldio-byq5psenbvn {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-byq5psenbvn div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
</style>
